<template>
  <div :class="['content']" :style="`background: ${template.color};  color: ${template.textColor || '#fff'};`">
    <div :class="['logo logo3', logoShow]"><img :src="template.logo" width="100%"></div>
    
    <div class="text">{{ text }}</div>

    <div :class="['img', showSmall, overflow]">
      <Qr :code="template.qrcode"  />
    </div>


  </div>
</template>

<script>
import Qr from './Qr.vue'
export default {
  props: ['template'],
  components: {Qr},
  data: () => ({
    logoShow: '',
    showSmall: '',
    overflow: '',
    text: "",
    stopWrite: false
  }),
  computed:{
    originalText(){
      return 'Aponte a câmera do seu celular para o código QR abaixo e confira a reportagem completa!'
    }
  },
  methods: {
    async write() {
      let total = this.originalText.length;
      while (total) {
        if(this.stopWrite) break;
        this.text += this.originalText[this.originalText.length - total];
        total--;
        await this.timeout(window.configs.timeWrite);
      }
      await this.timeout(window.configs.timeStage2)
      this.$emit('end')
    },
    timeout(time) {
      return new Promise((resolve) => setTimeout(resolve, time || 1000));
    },
  },
  mounted() {
    setTimeout(()=>this.showSmall='show-small',50)
    setTimeout(()=>{
      this.logoShow = 'logo-show'
      this.overflow='overflow'
      }, 1000)
    this.write();
  },
};
</script>

<style  scoped>

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fb611c;
  font-size: 47pt;
  font-family: tn;
}

.content *{
  transition: all .5s ease;
}

.logo{
  position: absolute;
  width: 400px;
  text-align: center;
  transition: all 1s ease;
}

.logo3{
  top: 5%;
  left: 340px;
  width: 400px;
  height: 0px;
  overflow: hidden;
}

.logo-show{
  height: 500px;
}

.text {
  top: 720px;
  padding:50px;
  text-align: center;
  font-size: 50pt;
  position: absolute;
  font-weight: bold;
}

.img {
  position: absolute;
  height: 0px;
  width: 0px;
  left: 240px;
  bottom: 100px;
  transform: translate(0, 0);
  animation: wave 10s infinite linear;
  overflow: hidden;
  /* transition: all 0.5s; */
  background: #000;
  /* border-radius: 100% 30% 80% 50%; */
}

.show-small{
  height: 600px;
  width: 600px;
}

.overflow{
  overflow: initial
}

@keyframes wave {
  0% {
    border-radius: 60% 80% 80% 50%;
  }

  20% {
    border-radius: 100% 50% 50% 80%;
  }

  40% {
    border-radius: 30% 70% 10% 70%;
  }

  60% {
    border-radius: 88% 50% 80% 50%;
  }

  80% {
    border-radius: 60% 80% 90% 100%;
  }

  100% {
    border-radius: 60% 80% 80% 50%;
  }
}
</style>
<template>
  <div class="content" :style="`background: ${template.color}; color: ${template.textColor || '#fff'};`">
    <marquee :template="template" />
    <div class="content-center">
      <div :class="['circle', circleTop]"></div>
      <dir :class="['text', textClose]" v-html="text"></dir>
    </div>
  </div>
</template>

<script>
import marquee from "./marquee.vue";
export default {
  props: ["template"],
  components: { marquee },
  data: () => ({
    circleTop: "",
    textClose: "",
    text: "",
    closeLeft: false,
    closeOpacity: false,
    stopWrite: false,
  }),
  computed: {
    originalText() {
      return (this.template.description || "").length > 370
        ? (this.template.description || "").slice(0, 370) + "..."
        : this.template.description || "";
    },
  },

  methods: {
    async end() {
      this.circleTop = "";
      this.textClose = "text-close";
      await this.timeout(500);
      this.$emit("end");
    },
    async write() {
      let total = this.originalText.length;
      while (total) {
        if (this.stopWrite) break;
        this.text += this.originalText[this.originalText.length - total];
        total--;
        await this.timeout(window.configs.timeWrite);
      }
      await this.timeout(window.configs.timeStage1);
      this.end();
    },
    timeout(time) {
      return new Promise((resolve) => setTimeout(resolve, time || 1000));
    },
  },
  mounted() {
    setTimeout(() => {
      this.circleTop = "circle-up";
      setTimeout(() => this.write(), 2000);
    }, 50);
    //this.randomBorders();
  },
};
</script>

<style  scoped>
a {
  text-decoration: none;
  color: initial;
}
.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fb611c;
  font-size: 47pt;
  font-family: tn;
}

.content-center {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
}

.circle {
  position: absolute;
  height: 80%;
  width: 130%;
  top: 100%;
  left: 3%;
  animation: wave 10s infinite linear;
  overflow: hidden;
  transition: all 3s;
  background: #fff;
  margin-left: -20%;
  border-radius: 100% 30% 80% 50%;
}

.text {
  z-index: 999;
  color: black;
  position: relative;
  font-weight: bold;
  font-size: 53pt;
  padding-top: 150px;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  text-align: center;
  opacity: 1;
  transition: all 1s ease;
}

.text-close {
  opacity: 0;
}

.circle-up {
  top: 15% !important;
}

@keyframes wave {
  0% {
    border-radius: 60% 80% 80% 50%;
  }

  20% {
    border-radius: 100% 90% 60% 90%;
  }

  40% {
    border-radius: 70% 40% 40% 70%;
  }

  60% {
    border-radius: 88% 50% 80% 50%;
  }

  80% {
    border-radius: 60% 80% 90% 100%;
  }

  100% {
    border-radius: 60% 80% 80% 50%;
  }
}
</style>
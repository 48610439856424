//services...
const homolog = {
  io: process.env.VUE_APP_BASE_URL_IO,
  rasp: process.env.VUE_APP_BASE_URL_RASP
}

const dev = {
  io: process.env.VUE_APP_BASE_URL_IO,
  rasp: process.env.VUE_APP_BASE_URL_RASP
 }

const prod = {
  io: process.env.VUE_APP_BASE_URL_IO,
  rasp: process.env.VUE_APP_BASE_URL_RASP
}

const baseUrls = {homolog, dev, prod}
const href = window.location.href
const isDev = href.includes('localhost') ? 'dev' : null
const isHomolog = href.includes('homolog') ? 'homolog' : null
const environment = isDev || isHomolog || 'prod'
const getBaseUrl = (env = environment) => baseUrls[env]

export default getBaseUrl()

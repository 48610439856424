var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('transition',{attrs:{"appear":"","name":_vm.current.animation,"mode":"in-out"}},[(
        _vm.show &&
        _vm.currentTemplate.name === 'Template 1' &&
        (_vm.currentTemplate.type != 'Imagem' ||
          _vm.currentTemplate.type != 'Vídeo') &&
        !['Vídeo', 'Imagem'].includes(_vm.current.mediaType)
      )?_c('Template1',{attrs:{"template":_vm.currentTemplate},on:{"end":_vm.nextFrame}}):_vm._e(),(
        _vm.show &&
        _vm.currentTemplate.name === 'Template 2' &&
        (_vm.currentTemplate.type != 'Imagem' ||
          _vm.currentTemplate.type != 'Vídeo') &&
        !['Vídeo', 'Imagem'].includes(_vm.current.mediaType)
      )?_c('Template2',{attrs:{"template":_vm.currentTemplate},on:{"end":_vm.nextFrame}}):_vm._e(),(
        _vm.show &&
        _vm.currentTemplate.name === 'Template 3' &&
        (_vm.currentTemplate.type != 'Imagem' ||
          _vm.currentTemplate.type != 'Vídeo') &&
        !['Vídeo', 'Imagem'].includes(_vm.current.mediaType)
      )?_c('Template3',{attrs:{"template":_vm.currentTemplate},on:{"end":_vm.nextFrame}}):_vm._e(),(
        _vm.show &&
        _vm.currentTemplate.name === 'Template 4' &&
        (_vm.currentTemplate.type != 'Imagem' ||
          _vm.currentTemplate.type != 'Vídeo') &&
        !['Vídeo', 'Imagem'].includes(_vm.current.mediaType)
      )?_c('Template4',{attrs:{"template":_vm.currentTemplate},on:{"end":_vm.nextFrame}}):_vm._e(),(
        _vm.showNotice &&
        _vm.current.itemType === 'Notícia' &&
        !['Vídeo', 'Imagem'].includes(_vm.current.mediaType)
      )?_c('Noticia',{staticClass:"slide",attrs:{"template":_vm.currentTemplate,"text":_vm.current.text,"color":_vm.current.color,"img":_vm.current.img,"qr":_vm.current.qrcode,"link":_vm.current.link,"copyrigth":_vm.current.copyright,"logo":_vm.current.logo,"time":_vm.current.time},on:{"end":_vm.nextFrame}}):_vm._e(),(
        _vm.show &&
        _vm.current.itemType == 'Anúncio' &&
        !['Vídeo', 'Imagem'].includes(_vm.current.mediaType)
      )?_c('Anuncio',{attrs:{"anuncio":_vm.current},on:{"endVideo":_vm.endVideo}}):_vm._e(),(['Vídeo', 'Imagem'].includes(_vm.current.mediaType))?_c('NoticiaMedia',{attrs:{"data":_vm.current},on:{"end":_vm.nextFrame}}):_vm._e()],1),(_vm.playlist.length)?_c('Cam',{attrs:{"current":_vm.current}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
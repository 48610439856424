<template>
  <div class="content" :style="`background: ${template.color};  color: ${template.textColor || '#fff'};`">
    <Logos :close="closeLogo" :template="template" />
    <div :class="['text', closeRight]" v-html="text"></div>

    <div
      ref="background"
      :class="['img', closeLeft]"
      :style="{
        'background-image': `url('${template.img}')`,
        'background-repeat': 'no-repeat',
        'background-size': '100% 100%',
      }"
    ></div>

    <div :class="['author-status', closeLeft]">
      <span
        style="background: #fff; color: #000"
        v-if="authorRoleText.length"
        >{{ authorRoleText }}</span
      >
    </div>
  </div>
</template>

<script>
import Logos from "./logos.vue";
export default {
  props: ["template"],
  components: { Logos },
  data: () => ({
    closeLogo: false,
    text: "",
    authorText: "",
    authorRoleText: "",
    closeLeft: false,
    closeRight: false,
    stopWrite: false,
  }),
  computed: {
    originalText() {
     
      return (this.template.description || "").length > 240
        ? (this.template.description || "").slice(0, 240) + "..."
        : this.template.description || "";
    },

    authorRole() {
      return this.template.text.length > 100
        ? this.template.text.slice(0, 100) + "..."
        : this.template.text || "Atributo do autor";
    },
  },
  watch: {
    originalText(v) {
      this.text = v;
    }
  },
  methods: {
    async close() {
      
      this.closeLeft = "close-left"
      this.closeRight = "close-right"
      await this.timeout(500);
      this.closeLogo = true;
      this.$emit("end");
      
    },
    async writeAuthorRole() {
      await this.timeout(2000);
      let total = this.authorRole.length;
      while (total) {
        if (this.stopWrite) break;
        this.authorRoleText += this.authorRole[this.authorRole.length - total];
        total--;
        await this.timeout(window.configs.timeWrite);
      }
    },

    async write() {
      this.writeAuthorRole();
      const text = this.originalText;
      let total = text.length;
      while (total) {
        if (this.stopWrite) break;
        this.text += text[text.length - total];
        total--;
        await this.timeout(window.configs.timeWrite);
      }
      await this.timeout(window.configs.timeStage1);
      this.close();
    },
    timeout(time) {
      return new Promise((resolve) => setTimeout(resolve, time || 1000));
    },
  },
  mounted() {
    this.write();

    //this.randomBorders();
  },
};
</script>

<style  scoped>

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 47pt;
  font-family: tn;
}
.text {
  position: absolute;
  top: 220px;
  text-align: center;
  height: 45%;
  width: 90%;
  left: 5%;
  transition: all 2s;
}

.text >>> a{
  text-decoration: none;
  color: inherit
}

.img {
  position: absolute;
  background-color: transparent;
  top: 50%;
  height: 50%;
  width: 100%;
  transform: translate(0, 0);
  animation: wave 15s linear;
  overflow: hidden;
  transition: all 1s;
  border-radius: 100% 30% 80% 50%;
}

.content span {
  padding: 17px;
  font-weight: bold;
}
.author-name {
  position: absolute;
  bottom: 300px;
  left: 100px;
  transition: all 1s;
}
.author-status {
  position: absolute;
  bottom: 100px;
  width: 100%;
  padding-left: 10px;
  padding-right: 60px;
  transition: all 1s;
  text-align: left;
  line-height: 100px;
}

.close-left {
  left: -3000px !important;
}

.close-right {
  left: 3000px !important;
}

@keyframes wave {
  0% {
    border-radius: 50% 10% 60% 90%;
  }

  20% {
    border-radius: 13% 13% 82% 39%;
  }

  40% {
    border-radius: 44% 81% 8% 54%;
  }

  60% {
    border-radius: 15% 37% 97% 74%;
  }

  80% {
    border-radius: 79% 83% 67% 40%;
  }

  100% {
    border-radius: 100% 30% 80% 50%;
  }
}
</style>
<template>
  <div class="content" :style="`background: ${template.color}; color: ${template.textColor || '#fff'};`">
    <Logos :template="template" />
    <dir :class="['text', textClose]" v-html="text"></dir>
  </div>
</template>

<script>
import Logos from "./logos.vue";
export default {
  props: ["template"],
  components: { Logos },
  data: () => ({
    textClose: "",
    text: "",
    closeLeft: false,
    closeOpacity: false,
    stopWrite: false,
  }),
  computed: {
    originalText() {
      return (this.template.description || "").length > 350
        ? (this.template.description || "").slice(0, 350) + "..."
        : this.template.description || '';
    },
  },

  methods: {
    async write() {
      for (const letra of this.originalText.split("")) {
        if (this.stopWrite) break;
        await this.timeout(window.configs.timeWrite);
        this.text += letra;
      }
      await this.timeout(window.configs.timeStage1);
      this.$emit("end");
    },
    timeout(time) {
      return new Promise((resolve) => setTimeout(resolve, time || 1000));
    },
  },
  mounted() {
    setTimeout(() => {
      this.write();
    }, 50);
  },
};
</script>

<style  scoped>
.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fb611c;
  font-size: 47pt;
  font-family: tn;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  background: black;
  position: absolute;
  top: 300px;
  left: 50px;
  width: 0%;
  padding: 20px;
  font-size: 70pt;
  font-weight: bold;
  transition: all 1s ease;
}

.title-open {
  width: 77%;
}

.text {
  position: relative;
  font-weight: bold;
  font-size: 53pt;
  padding: 70px;
  text-align: center;
  opacity: 1;
  transition: all 1s ease;
}

.text >>> a {
  text-decoration: none;
  color: inherit;
}

.last-text {
  position: absolute;
  bottom: 50px;
  text-align: center;
  padding: 100px;
  font-family: "Courier New", Courier;
  font-weight: bold;
}
</style>
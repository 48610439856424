<template>
  <div class="home">
    <transition appear :name="current.animation" mode="in-out">
      <Template1
        v-if="
          show &&
          currentTemplate.name === 'Template 1' &&
          (currentTemplate.type != 'Imagem' ||
            currentTemplate.type != 'Vídeo') &&
          !['Vídeo', 'Imagem'].includes(current.mediaType)
        "
        :template="currentTemplate"
        @end="nextFrame"
      />
      <Template2
        v-if="
          show &&
          currentTemplate.name === 'Template 2' &&
          (currentTemplate.type != 'Imagem' ||
            currentTemplate.type != 'Vídeo') &&
          !['Vídeo', 'Imagem'].includes(current.mediaType)
        "
        :template="currentTemplate"
        @end="nextFrame"
      />
      <Template3
        v-if="
          show &&
          currentTemplate.name === 'Template 3' &&
          (currentTemplate.type != 'Imagem' ||
            currentTemplate.type != 'Vídeo') &&
          !['Vídeo', 'Imagem'].includes(current.mediaType)
        "
        :template="currentTemplate"
        @end="nextFrame"
      />
      <Template4
        v-if="
          show &&
          currentTemplate.name === 'Template 4' &&
          (currentTemplate.type != 'Imagem' ||
            currentTemplate.type != 'Vídeo') &&
          !['Vídeo', 'Imagem'].includes(current.mediaType)
        "
        :template="currentTemplate"
        @end="nextFrame"
      />
      <!-- <Template5
        v-if="
        show &&
          currentTemplate.name === 'Template 5' &&
          (currentTemplate.type != 'Imagem' || currentTemplate.type != 'Vídeo')
        "
        :template="currentTemplate"
        @end="nextFrame"
      /> -->
      <Noticia
        v-if="
          showNotice &&
          current.itemType === 'Notícia' &&
          !['Vídeo', 'Imagem'].includes(current.mediaType)
        "
        :template="currentTemplate"
        @end="nextFrame"
        class="slide"
        :text="current.text"
        :color="current.color"
        :img="current.img"
        :qr="current.qrcode"
        :link="current.link"
        :copyrigth="current.copyright"
        :logo="current.logo"
        :time="current.time"
      />
      <Anuncio
        v-if="
          show &&
          current.itemType == 'Anúncio' &&
          !['Vídeo', 'Imagem'].includes(current.mediaType)
        "
        :anuncio="current"
        @endVideo="endVideo"
      />
      <NoticiaMedia
        v-if="['Vídeo', 'Imagem'].includes(current.mediaType)"
        :data="current"
        @end="nextFrame"
      />
    </transition>
    <Cam v-if="playlist.length" :current="current" />
  </div>
</template>

<script>
import Cam from "../Cam/Cam";
import Noticia from "./Noticia.vue";
import Template1 from "./template1/index.vue";
import Template2 from "./template2/index.vue";
import Template3 from "./template3/index.vue";
import Template4 from "./template4/index.vue";
import NoticiaMedia from "./NoticiaMedia.vue";
import Anuncio from "./Anuncio";
export default {
  props: ["playlist"],
  components: {
    Noticia,
    Template1,
    Template2,
    Template3,
    Template4,
    NoticiaMedia,
    Anuncio,
    Cam,
  },
  data: () => ({
    pause: false,
    show: false,
    current: {},
    currentIndex: 0,
    currentTemplate: {},
    frames: [],
  }),
  computed: {
    showNotice() {
      if (this.currentTemplate.name) {
        return this.currentTemplate.name == "Template 5" && this.show;
      }
      return !(this.current.templates || []).length && this.show;
    },
  },
  methods: {
    playFrame() {
      this.currentTemplate = {};
      this.$nextTick(
        () =>
          (this.currentTemplate = {
            ...(this.current.templates[0] || {}),
            index: 0,
          })
      );
    },
    async nextFrame(index) {
      if (this.current.templates[index + 1]) {
        this.show = false
        this.$nextTick(()=>{
          this.currentTemplate = {
            ...this.current.templates[index + 1],
            index: index + 1,
          };
          this.show = true
        })
      } else {
        this.currentTemplate = {};
        this.next();
      }
    },

    async endVideo() {
      this.show = false;
      this.pause = false;
      await this.timeout(500);
      this.$nextTick(this.toggleOpacity);
    },
    timeout(time) {
      return new Promise((resolve) => setTimeout(() => resolve(), time));
    },
    async next() {
      this.show = false;
      const current = this.playlist.slice(
        this.currentIndex,
        this.currentIndex + 1
      )[0];

      if (!current) {
        await this.timeout(500);
        return window.location.reload();
      }

      current.templates.forEach((item) => {
        item.qrcode = current.qrcode;
      });

      this.current = current;

      if (this.current.templates.length) {
        this.currentIndex = this.currentIndex + 1;
        this.show = true;
        return this.playFrame();
      }

      await this.timeout(1000);

      if (!current) {
        await this.timeout(500);
        return window.location.reload();
      }

      this.currentIndex = this.currentIndex + 1;
      this.current = current ? current : this.playlist[this.currentIndex];

      if (current.type == "Vídeo") {
        this.pause = true;
        this.show = true;
        return;
      }

      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    async toggleOpacity() {
      await this.next();
      if (this.pause) {
        return false;
      }
    },
  },
  mounted() {
    this.toggleOpacity();
  },
};
</script>

<style scoped>
.home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 1s;
}
.bounce-leave-active {
  animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>

import { io } from 'socket.io-client'
import baseUrls from '../base-urls'
const socket = (totenId) => {
  const conn = io(baseUrls.io, {path:'/socket.io', transports: ["websocket"], query: {totenId}});
  setInterval(()=>{
    !conn.connected ? window.document.location.reload() : null
  }, 30000)
  return conn
}

export default socket
import axios from 'axios'
import baseUrls from '../base-urls.js'
const rasp = axios.create({ baseURL: baseUrls.rasp })
export const getTotemById = (totenId) => rasp
  .get(`/rasp/${totenId}`)
  .then(({ data }) => data)
  .catch(err => {
    window.alert(err)
    return Promise.reject(new Error(err))
  })

export const getPlaylist = totemId => rasp.get('/playlists/' + totemId)
  .then(({ data }) => {
     return data.map(item => ({...item, templates: item.templates || []}))
  })
  .catch(err => Promise.reject(new Error(err)))

export const facesDetected = (data) => rasp.post('/faces-detected/', data).then(({ data }) => data)
  .catch(err => Promise.reject(new Error(err)))

export const getClicks = id => rasp.get('/r/' + id).then(({ data }) => data).catch(err => Promise.reject(new Error(err)))
<template>
  <div>
    <img :src="anuncio.link" alt="" v-if="anuncio.type == 'Imagem'" />
    <video
      muted
      autoplay
      @ended="$emit('endVideo')"
      v-if="anuncio.type == 'Vídeo'"
    >
    <source :src="anuncio.link">
    </video>
    <qr :code="anuncio.qrcode" v-if="anuncio.showQr" />
  </div>
</template>

<script>
import Qr from "../Qr/Qr.vue";
export default {
  components: { Qr },
  props: ["anuncio"],
  mounted(){
    if(this.anuncio.type == 'Imagem'){
      setTimeout(() => this.$emit('endVideo'), this.anuncio.time)
    }
  },
  watch:{
    "anuncio.type"(v){
      if(v === 'Image'){
        setTimeout(() => this.$emit('endVideo'), this.anuncio.time)
      }
    }
  }
};
</script>

<style scoped>
img {
  widows: 100%;
}
video{
  width:1080px;
  height: 1920px;
}
</style>
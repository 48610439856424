<template>
  <div ref="qrcode" class="qrcode" >
  </div>
</template>

<script>
export default {
  props: ["code"],
  methods: {
    generateQrcode() {
      new window.QRCode(this.$refs.qrcode, {
        text: this.code,
        width: 250,
        height: 250,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: window.QRCode.CorrectLevel.H,
      });
    },
  },
  mounted() {
    this.generateQrcode();
  },
};
</script>

<style scoped>
.qrcode {
  position: absolute;
  bottom: 140px;
  width: 400px;
  height: 400px;
  overflow: hidden;
  left: 100px;
  top: 100px;  
  background: #fff;
}
.qrcode >>> img {
  display: block;
  text-align: center;
  width: 400px;
}

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../components/App/App.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'App',
    component: App
  },
  {
    path: '/debug',
    name: 'Debug',
    component: () => import(/* webpackChunkName: "about" */ '../components/Debug/Debug.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

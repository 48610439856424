<template>
  <div ref="qrcode" class="qrcode" :style="{ border: 'solid 10px ' + color }">
    <div class="leiamais" v-if="showLabel">LEIA MAIS!</div>
  </div>
</template>

<script>
export default {
  props: ["color", "code", "showLabel"],
  methods: {
    generateQrcode() {
      new window.QRCode(this.$refs.qrcode, {
        text: this.code,
        width: 250,
        height: 250,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: window.QRCode.CorrectLevel.H,
      });
    },
  },
  mounted() {
    this.generateQrcode();
  },
};
</script>

<style scoped>
.qrcode {
  z-index: 1;
  position: absolute;
  bottom: 140px;
  width: 300px;
  height: 300px;
  overflow: hidden;
  right: 50px;
  border-radius: 30px;
  padding-top: 30px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
}
.qrcode >>> img {
  display: block;
  text-align: center;
  margin: 10px auto;
  width: 250px;
}

.leiamais {
  margin-top: -20px;
  font-weight: 900;
  font-size: 25pt;
  padding: 0 20px;
}
</style>
<template>
  <div class="content">
    <img src="@/assets/territorio.png" class="logo-top" alt="">
    <div class="dec" :style="{'border-color': color}">
      <img
        :src="img"
        alt=""
        width="1080"
        height="1000"
      />
      <div class="copyright-image" v-if="copyrigth"> <b>Foto:</b> {{copyrigth}}</div>
    </div>

    <div class="dot" :style="{'background-color': color}"></div>
    <div class="text" v-html="text"></div>

    
    <div class="logo"><img :src="logo" width="340" alt=""></div>
    <Qr :color="color" :code="qr" :showLabel="true" />
    <div class="foot" :style="{background: color}">
    <div class="textfoot"><b>FONTE</b> {{linkComputed}}</div>  
    </div>
  </div>
</template>
<script>
import Qr from "@/components/Qr/Qr.vue"
export default {
  props: ['img', 'color', 'text', 'qr', 'link', "copyrigth", "logo", 'template', "time"],
  components: {Qr},
  name: "Anuncio",
  computed:{
    linkComputed(){
      return new URL(this.link).origin
    }
  },
  mounted(){
    setTimeout(()=>this.$emit('end'), this.time)
    
  }
};
</script>

<style scoped>
@font-face {
  font-family: 'tn';
  src: url('/fonts/HurmeGeometricSans1.otf');
}

@font-face {
  font-family: 'tn-bold';
  src: url('/fonts/HurmeGeometricSans1_Bold.otf');
}

@font-face {
  font-family: 'tn-semibold';
  src: url('/fonts/HurmeGeometricSans1_SemiBold.otf');
}

.copyright-image{
  position: absolute;
  text-shadow: 2px 2px #000;
  height: 50px;
  bottom: 60px;
  left: 50px;
  color: white;
}
.logo-top{
  position: absolute;
  width: 300px;
  left: 0px;
  top: 25px;
}
.logo{
  position: absolute;
  bottom: 150px;
  left: 20px;
  background: white;
}
.textfoot{
  color:white;
  position: absolute;
  top: 50px;
  left: 20px;
  font-family: 'tn';
  font-size: 17pt;
}

.foot{
  position: absolute;
  bottom: -80px;
  width: 1100px;
  height: 250px;
  left: -5px;
  transform: rotate(-5deg);
}
.foot >>>* {
   transform: rotate(5deg);
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: 'tn-semibold';
  font-weight: bold;
  font-size: 30pt
}
.dec {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-top: 135px;
  left: 0;
  width: 1188px;
  height: 768px;
  /* background-color: rgb(228, 128, 128); */
  margin-left: -54px;
  border-bottom-left-radius: 0;
  border-top: solid 10px;
  border-bottom: solid 10px;
  border-top-left-radius: 324px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 324px;
  transform: rotate(-6deg);
}
.dec >* {
  transform: rotate(6deg);
  margin-top: -100px;
}

.dot {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 850px;
  top: 865px;
  border-radius: 50px;
}
.text{
  margin-top: 70px;
  padding: 15px 25px;
  font-weight: bold;
  font-size: 40pt;
  font-family: 'tn';
  text-transform: uppercase;
}
</style>

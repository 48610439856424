<template>
  <div>
    <video ref="video" width="800" autoplay="true" :style="{visibility: 'hidden', display: 'fixed'}" muted></video>
  </div>
</template>

<script>
import { facesDetected } from '../../services/rasp';
export default {
  props: ['current'],
  data: () => ({
    video: null,
    img: document.createElement("img"),
    canvas: document.createElement("canvas"),
    ctx: null,
  }),
  methods: {
    reconhece() {
      this.canvas.width = this.video.offsetWidth;
      this.canvas.height = this.video.offsetHeight;
      this.ctx.filter = "brightness(1.7)";
      this.ctx.drawImage(
        this.video,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      this.img.src = this.canvas.toDataURL("image/jpeg", 0.5);
    },
    onloadImage() {
      return window.$(this.img).faceDetection({
        complete: async (faces) => {
          const qtd = faces.filter((fc) => fc.confidence > 0);
          if(qtd.length > 0){
            await facesDetected({qtd: qtd.length, item: this.current, totemId: localStorage.getItem('totenId')}).catch(console.error)
          }
          setTimeout(this.reconhece, 3000);
        },
      });
    },
    init() {
      this.img.onload = this.onloadImage;
      this.video = this.$refs.video;
      this.ctx = this.canvas.getContext("2d", {willReadFrequently: true});
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "user" }, audio: false })
        .then((stream) => {
          this.video.srcObject = stream;
          this.reconhece();
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
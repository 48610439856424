<template>
  <div>
    <Login v-if="!connected" @totenId="start" />
    
    <Home v-if="connected && playlist.length" :playlist="playlist" />
    <div class="black" v-if="!playlist.length && connected"></div>
  </div>
</template>

<script>

import Home from "../Templates/Home";
import Login from "../Login/Login";
import io from "../../services/io";
import {getPlaylist} from '../../services/rasp'
export default {
  name: "App",
  components: {
    Home,
    Login,
  },
  data: () => ({
    connected: false,
    playlist: [
      // {
      //   itemType: "Notícia",
      //   animation: "bounce",
      //   color: "blue",
      //   text: `"É COMO SE FOSSE UM UBER DE ENTREGAR CESTAS BÁSICAS": JOGO APOIA AÇÕES SOLIDÁRIAS NAS PERIFERIAS.`,
      //   img:
      //     "https://college.canon.com.br/static/img/tutorial/c6424436-4b9f-45a6-8c6f-066e7d9b9345.jpg",
      //   qrCode:
      //     "https://college.canon.com.br/static/img/tutorial/c6424436-4b9f-45a6-8c6f-066e7d9b9345.jpg",
      //   time: 5000,
      // },
      // {
      //   itemType: "Anúncio",
      //   type: "Image",
      //   animation: "bounce",
      //   img: "/img/anuncio.ad1f4558.png",
      //   qrCode: "/img/anuncio.ad1f4558.png",
      //   time: 5000,
      // },
      // {
      //   type: "Vídeo",
      //   animation: "bounce",
      //   video: "/media/anuncio.2af65bf9.mp4",
      //   qrCode: "/media/anuncio.2af65bf9.mp4",
      //   time: 5000,
      // }
    ],
    socket: null,
  }),
  methods: {
    start() {
      const totenId = localStorage.getItem("totenId");

      if (totenId) {
        getPlaylist(totenId)
        .then(data => {
          if(data[0] && data[0].debug) return this.$router.push('/debug')
          this.playlist = data.map(item => ({...item, templates: [...item.templates.map(template => ({...template, ...item}))]}))
          })
        .catch((err)=>{
          console.error("falha na conexão, tentando novamente", err)
          setTimeout(() => getPlaylist(totenId)
        .then(data => this.playlist = data), 1000)
          })
        this.socket = io(totenId);
        this.connected = true
        this.socket.on('playlist', ()=>window.document.location.reload())
        //direciona o totem para página de debug
        this.socket.on('debug', ()=>this.$router.push('/debug'))
        //direciona o totem para página de playlists
        this.socket.on('noDebug', ()=>this.$router.push('/'))
      }
    },
  },
  mounted() {
    this.start();
  },
};
</script>

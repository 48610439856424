<template>
 
    <div class="prev">
      <img
        :src="data.link"
        alt=""
        v-if="data.mediaType == 'Imagem'"
      />
      <video
        v-else-if="data.mediaType == 'Vídeo' && data.link"
        :src="data.link"
        muted
        autoplay
        @ended="endVideo"
      ></video>
      <Qr :color="data.color" v-if="data.showQr" />
    </div>

</template>

<script>
import Qr from "@/components/Qr/Qr.vue"
export default {
  props: ['data'],
  components:{Qr},
  name: "PreviewMedia",
  methods:{
    endVideo(){
      return this.$emit('end')
    }
  },
  mounted(){
    if(this.data.mediaType === 'Imagem'){
      setTimeout(() => this.$emit('end'), this.data.time)
    }
  },
  watch:{
    'data.mediaType'(){
      if(this.data.mediaType === 'Imagem'){
      setTimeout(() => this.$emit('end'), this.data.time)
    }
    }
  }
};
</script>

<style scoped>
.prev {
    position: relative;
  width: 1080px;
  height: 1920px;
}
video,
img {
  width: 1080px;
  height: 1920px;
}
.imgPrev{
    position:absolute;
    bottom: 200px;
    right: 100px;
    background: #fff;    
    width: 280px;
    height: 280px;
    border-radius: 50px;
    border: solid 10px #000;
    overflow: hidden;
    text-align: center;
}
.imgPrev img{
    
width: 180px;
height: 180px;
bottom: 0;
}
.leiamais{
  font-family: arial;
  text-align: center;
padding-bottom: 20px;
  font-weight: 900;
  font-size: 25pt;
  font-family: monospace;
}
</style>
<template>
  <div class="login">
    <div>
      Informe o id do totekn
      <input type="text" ref="totenId" v-model="totenId">
      <input type="button" value="OK" :disabled="!totenId" @click="saveTotem">
    </div>
  </div>
</template>

<script>
import {getTotemById} from '@/services/rasp'
  export default {
    data: ()=>({
      totenId: null
    }),
    methods:{
      saveTotem(){
        if(!this.totenId){
          return alert('id do totem deve ser informado')
        }
        getTotemById(this.totenId)
        .then(() => {
          localStorage.setItem('totenId', this.totenId)
          this.$emit('totenId', this.totenId)
          document.documentElement.webkitRequestFullScreen()
        })
        .catch((err) => alert('Totem não encontrado ou sistema está fora do ar' + err.stack))
      }
    }
  }
</script>

<style scoped>
input{
  font-size: 15pt;
}

.login{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-family: arial;
  font-size: 15pt;
}
</style>